var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _DesktopFloatManager_floatingEnabled;
import { Throttle } from 'nyp-shared-assets';
/**
 * Manages JW Player desktop floating logic to ensure the players only float
 * under strict conditions.
 *
 * Reference:
 * - https://alleyinteractive.atlassian.net/browse/NYP-15209
 * - https://alleyinteractive.atlassian.net/browse/NYP-20841
 */
var DesktopFloatManager = /** @class */ (function () {
    /**
     * Start the desktop float manager.
     *
     * @param {object} player A JW Player instance.
     * @param {object} config Additional configuration options.
     */
    function DesktopFloatManager(player, config) {
        var _this = this;
        if (config === void 0) { config = {}; }
        /**
         * Tracks whether or not the floating player is allowed.
         * @private
         *
         * @type {boolean}
         */
        _DesktopFloatManager_floatingEnabled.set(this, false);
        /**
         * The player 'ready' event callback.
         */
        this.handlePlayerReady = function () {
            var _a;
            if (!_this.player) {
                console.error('DesktopFloatManager error: A player is required.'); // eslint-disable-line no-console
            }
            else {
                var _b = _this.player.getConfig().floating, _c = _b === void 0 ? {} : _b, _d = _c.mode, mode = _d === void 0 ? 'never' : _d;
                /*
                 * Distinguishing between desktop and mobile in `OS.mobile` is valid.
                 * See: https://alleyinteractive.slack.com/archives/C02SM1ULPJ9/p1663185826991769?thread_ts=1663179705.658629&cid=C02SM1ULPJ9.
                 */
                var _e = _this.player.getEnvironment().OS, _f = _e === void 0 ? {} : _e, mobile = _f.mobile;
                /*
                 * Business logic:
                 *  - Player has floating enabled.
                 *  - Desktop view.
                 *  - Not a standalone video player post type (ie: video).
                 */
                _this.isReady = (mode !== 'never'
                    && mobile === false
                    && ((_a = _this.config) === null || _a === void 0 ? void 0 : _a.postType) !== 'video');
            }
            if (_this.isReady) {
                // Disable floating by default until we know whether the container is in view.
                _this.playerContainerIsViewable = false;
                /*
                 * JW Player includes an intersection observer that tells us how much of
                 * the player container is viewable, so we don't need to add one of our own.
                 * Instead, we can listen to the scroll event and check container viewability
                 * via the player API.
                 */
                _this.throttle = new Throttle(window, 'scroll', _this.checkContainerViewable);
                /*
                 * Avoid the sticky billboard ad. This method includes a check for
                 * `floatingEnabled`, which will allow the observer to respond immediately
                 * if/when we enabled floating.
                 */
                if (_this.bannerAd !== null) {
                    _this.floatAroundStickyBillboard();
                }
            }
        };
        /**
         * Allows the player to float only after the user has scrolled its container
         * into view. The 'container' is defined as the element containing it's initial
         * position on the page.
         */
        this.checkContainerViewable = function () {
            // Decimal value 0—1 representing the percentage of the container that's viewable.
            if (_this.player.getContainerPercentViewable() === 1) {
                _this.playerContainerIsViewable = true;
                if (_this.throttle instanceof Throttle) {
                    _this.throttle.destroy();
                }
            }
        };
        /**
         * Forces a player to float when its container is obstructed by the billboard ad.
         */
        this.floatAroundStickyBillboard = function () {
            var playerContainer = _this.player.getContainer();
            var bannerAdBounds = _this.bannerAd.getBoundingClientRect();
            /*
             * Track the visibility ratio to know whether the target element is becoming
             * more or less visible.
             * See https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
             */
            var prevRatio = 0.0;
            /**
             * Generates an array of decimal values between 0 and the given number.
             *
             * @param {number} len   The length of the returned array.
             * @param {number} max=1 The numerator to be divided into `len` points.
             * @return {array}
             */
            var getThreshold = function (len, max) {
                if (max === void 0) { max = 1; }
                return (Array.from({ length: len }, function (el, idx) { return parseFloat((idx * (max / (len - 1))).toFixed(1)); }));
            };
            /**
             * Observe the document and float the player if it is obstructed by the
             * sticky billboard ad.
             */
            var observer = new IntersectionObserver(function (entries) {
                entries.forEach(function (entry) {
                    var playerShouldFloat = (
                    /*
                     * Ensure the player container has previously been scrolled into view,
                     * otherwise a value less than 1 might be confused for it being behind
                     * the billboard.
                     */
                    _this.floatingEnabled
                        && (
                        // User is scrolling DOWN, player is moving UP toward or behind the billboard.
                        entry.intersectionRatio < prevRatio
                            // The amount (0-1) of the player visible outside of the sticky banner area.
                            || entry.intersectionRatio < 0.9));
                    _this.player.setFloating(playerShouldFloat);
                    prevRatio = entry.intersectionRatio;
                });
            }, {
                // Increments of when intersection executes.
                threshold: getThreshold(10),
                // This can specify the parent container, `null` is the document.
                root: null,
                /*
                 * Shrink the root element, in our case Document, by the height of the
                 * billboard ad, meaning an `intersectionRatio` of less than 1 means the
                 * player container is either not yet in view or has been scrolled behind
                 * the billboard ad.
                 */
                rootMargin: "-".concat(bannerAdBounds.height, "px 0px ").concat(bannerAdBounds.height, "px 0px"),
            });
            /**
             * Handle the sticky billboard ad event.
             *
             * @param {Event} event The Event object.
             */
            var handleBillboardStickyChange = function (event) {
                var isSticky = event.detail.isSticky;
                if (isSticky === true) {
                    // Conditionally float the player.
                    observer.observe(playerContainer);
                }
                else if (isSticky === false) {
                    // Clear the intersection observer.
                    observer.unobserve(playerContainer);
                    /*
                     * Restore player defaults only if floating is enabled. This is required
                     * because our observer may have disabled floating via `setFloating(false)`,
                     * which changes the player config. If floating is not enabled yet,
                     * e.g., the player container is further down the page, we don't want
                     * to change the config.
                     */
                    if (_this.floatingEnabled) {
                        _this.restorePlayerFloatingMode();
                    }
                }
            };
            _this.bannerAd.addEventListener('nyp.billboardStickyChange', handleBillboardStickyChange);
        };
        /**
         * Restores the initial player floating behavior.
         */
        this.restorePlayerFloatingMode = function () {
            _this.player.setConfig({
                floating: {
                    mode: 'notVisible',
                },
            });
        };
        this.player = player;
        this.config = config;
        /**
         * Whether all business logic is satisfied for this player.
         *
         * @type {boolean}
         */
        this.isReady = false;
        /**
         * Save the billboard ad for later.
         *
         * @type {HTMLElement}
         */
        this.bannerAd = document.querySelector('.billboard-overlay');
    }
    Object.defineProperty(DesktopFloatManager.prototype, "playerContainerIsViewable", {
        /**
         * Sets whether or not the player's container is viewable, at which point we
         * can allow the floating player.
         *
         * @param {boolean} isVisible Whether the player container has been scrolled into view.
         */
        set: function (isVisible) {
            __classPrivateFieldSet(this, _DesktopFloatManager_floatingEnabled, isVisible, "f");
            if (this.floatingEnabled) {
                this.restorePlayerFloatingMode();
            }
            else {
                this.player.setFloating(false);
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DesktopFloatManager.prototype, "floatingEnabled", {
        /**
         * Returns true if our business logic allows the player to float.
         *
         * @return {boolean}
         */
        get: function () {
            return __classPrivateFieldGet(this, _DesktopFloatManager_floatingEnabled, "f");
        },
        enumerable: false,
        configurable: true
    });
    return DesktopFloatManager;
}());
_DesktopFloatManager_floatingEnabled = new WeakMap();
export default DesktopFloatManager;
